import React from "react"
import { graphql } from "gatsby"
import { RichText } from "prismic-reactjs"

import htmlSerializer from "../utils/htmlSerializer"

import Layout from "../components/layout"
import SEO from "../components/shared/seo"
import Button from "../components/shared/button"
import Banner from "../components/widgets/banner"
// import FeatureText from "../components/widgets/featureText"

import Reveal from "react-reveal/Reveal"

const SupportPage = ({ data }) => {
  const doc = data.prismic.allSupports.edges.slice(0, 1).pop()
  if (!doc) return null

  return (
    <Layout pageTitle={doc.node.page_heading[0].text} logoType={`heart`}>
      <SEO
        title={
          doc.node.meta_title
            ? doc.node.meta_title
            : doc.node.page_heading[0].text
        }
        image={doc.node.meta_image}
        description={doc.node.meta_description}
        pageTheme={`dark`}
      />

      <Banner content={doc.node.banner_title} />

      <Reveal effect="fadeInUp" fraction={0.05}>
        <section className={`grid-container`}>
          <div className={`grid-x`}>
            <div className={`cell`}>
              <RichText
                render={doc.node.main_content}
                htmlSerializer={htmlSerializer}
              />

              <Button
                text={doc.node.support_button_text}
                link={doc.node.support_button_link}
                emoji={doc.node.support_button_emoji}
                emojiLabel={doc.node.support_button_emoji_label}
              />
            </div>
          </div>
        </section>
      </Reveal>
    </Layout>
  )
}

export const query = graphql`
  query SupportPageQuery {
    prismic {
      allSupports {
        edges {
          node {
            meta_title
            meta_description
            meta_image
            meta_imageSharp {
              publicURL
            }
            page_title
            page_heading
            banner_title
            main_content
            support_button_text
            support_button_link {
              _linkType
              ... on PRISMIC_Homepage {
                _meta {
                  type
                }
              }
              ... on PRISMIC_Support {
                _meta {
                  type
                }
              }
              ... on PRISMIC_Blog_list {
                _meta {
                  type
                }
              }
              ... on PRISMIC_Blog_post {
                _linkType
                _meta {
                  type
                  uid
                }
              }
              ... on PRISMIC_Our_work {
                _meta {
                  type
                }
              }
              ... on PRISMIC_Person {
                _meta {
                  type
                }
              }
              ... on PRISMIC_Approach {
                _meta {
                  type
                }
              }
              ... on PRISMIC_About {
                _meta {
                  type
                }
              }
              ... on PRISMIC_Basic_page {
                _linkType
                _meta {
                  type
                  uid
                }
              }
              ... on PRISMIC_Case_study {
                _linkType
                _meta {
                  type
                  uid
                }
              }
              ... on PRISMIC__ExternalLink {
                _linkType
                url
              }
            }
            support_button_emoji_label
            support_button_emoji
          }
        }
      }
    }
  }
`

export default SupportPage
